<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">新建出库</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="search">
				<el-input v-model="productCode" @keyup.enter.native="enterProductCode" style="width:300px;"
					placeholder="请用扫码枪扫描商品码"></el-input>
				<el-button type="primary" @click="dialogProduct_state = true">选择出库商品</el-button>
				<div class="btnView">
					<el-button type="primary" @click="handleAddGoodsClick">新建商品</el-button>
				</div>
			</div>
			<div class="viewC">
				<el-table :data="tableData">
					<el-table-column prop="newData.barCode" label="条形码">
					</el-table-column>
					<el-table-column prop="newData.productName" label="名称">
					</el-table-column>
					<el-table-column prop="newData.modelValue" label="规格">
					</el-table-column>
					<el-table-column prop="newData.originalPrice" label="零售价" width="100">
					</el-table-column>
					<el-table-column prop="newData.currentStock" label="当前库存" width="100">
					</el-table-column>
					<el-table-column label="出库数量">
						<template slot-scope="scope">
							<el-input-number v-model="scope.row.newData.count" :min="1" size="small"></el-input-number>
						</template>
					</el-table-column>
					<el-table-column label="备注">
						<template slot-scope="scope">
							<el-input v-model="scope.row.newData.remark" placeholder="请输入备注" clearable size="small">
							</el-input>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleDeleteClick(scope.$index)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="viewB">
				<el-select v-model="outOrderType" placeholder="请选择出库类型" style="width: 160px;margin-right: 20px;">
					<el-option v-for="item in outOrderTypeOptions" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
				<el-button style="width: 110px;" @click="dialogRemark_state = true">备注</el-button>
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交</el-button>
			</div>
		</div>
		<div v-if="dialogProduct_state">
			<dialogProduct @submit="submit_dialogProduct" :tree="tree" :selectedList="tableData"></dialogProduct>
		</div>
		<div v-if="dialogRemark_state">
			<dialogRemark @submit="submit_dialogRemark" :remarkType="remarkType" :remark="remark"></dialogRemark>
		</div>
	</div>
</template>

<script>
	import dialogProduct from "../../../components/dialogProduct.vue"
	import dialogRemark from "../../../components/dialogRemark.vue"
	export default{
		components:{
			dialogProduct,
			dialogRemark
		},
		data() {
			return {
				tableData:[],
				dialogProduct_state:false,
				dialogRemark_state:false,
				tree: [{code:'goodstype'}],
				remarkType:1,  //备注类型
				remark:'',  //整体备注
				outOrderType: '', //出库类型
				outOrderTypeOptions: [], //出库类型列表
				productCode:''
			}
		},
		mounted() {
			this.getOutTypeData()
		},
		methods:{
				// 输入条码
				enterProductCode(){
				if(this.productCode.length===0){
					return ;
				}
				this.tableData.forEach((item) => {
					console.log(item,'table');
        if (item.newData.barCode ==this.productCode) {
					this.$message({
						type: 'error',
						message: '重复录入'
					});
          return ;
        }
       return
      })
				let params = {
					keyword:this.productCode,
				}
				this.$http.get("/product/goods/from/keyword",params).then(res=>{
					if(res.code===0){
						this.productCode = ''
						this.tableData.push({
							originalData: res.data,
						newData:{
							"productId": res.data.goodsId,
							"productName": res.data.title,
							"productType": 1,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
							"brand": res.data.brand.name,
							"firstCategoryId": res.data.firstCategoryInfo.id,
							"firstCategoryName": res.data.firstCategoryInfo.name,
							"secondCategoryId": res.data.secondCategoryInfo.id,
							"secondCategoryName": res.data.secondCategoryInfo.name,
							"modelValue": res.data.model,
							"unit": "",
							"originalPrice": res.data.salePrice,
							"price": res.data.salePrice,
							"costPrice": res.data.currentCostPrice,
							"num": 1,
							"activityId": "",
							"activityName": "",
							"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
							"activityItem": {},
							"activityList":[],
							"payType": "",  // 
							"remark": "",
							"isGive": false,  // 是否赠送
							"buyBack": res.data.buyBack,  // 是否复购
							tableKey:res.data.goodsId + Math.random(),
							"petId": "",
							"petName": "",
							"petClass":-1,
							"staffIds":[],
							"salesList": [],
							"productDetails":[],
							"barCode":res.data.barCode,
							"currentStock":res.data.currentCount,
							"count":1,
							"totalAmount":0,
						}
						})
						console.log(this.tableData,'???????');
					}
				})
			},
			getOutTypeData(){
				this.$http.get("/store/out/type").then(res => {
					if (res.code === 0) {
						this.outOrderTypeOptions = res.data
					}
				})
			},
			//获取弹框
			submit_dialogRemark(obj){
				this.remark = obj.data;
				this.dialogRemark_state = false;
			},
			//提交
			handleSubmitClick() {
				if(this.tableData.length == 0){
					this.$message({
						type: 'error',
						message: '请选择出库商品'
					});
					return
				}
				if(this.outOrderType == ''){
					this.$message({
						type: 'error',
						message: '请选择出库类型'
					});
					return
				}
				let arr = [];
				this.tableData.map((item)=>{
					arr.push(item.newData)
				})
				let params = {
					remark:this.remark,
					outOrderItems:arr
				};
				if(this.outOrderType == '2'){   //采购退货
					this.$http.post("/store/out/PurchaseBack",params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已提交'
							});
							this.$router.go(-1);
						}
					})
				}else if(this.outOrderType == '7'){  //报废出库
					this.$http.post("/store/out/Scrap",params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已提交'
							});
							this.$router.go(-1);
						}
					})
				}else if(this.outOrderType == '6'){  //自用出库
					this.$http.post("/store/out/SelfUse",params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已提交'
							});
							this.$router.go(-1);
						}
					})
				}else if(this.outOrderType == '3'){  //兑换出库
					this.$http.post("/store/out/Exchange",params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已提交'
							});
							this.$router.go(-1);
						}
					})
				}
			},
			//商品确认
			submit_dialogProduct(obj) {
				this.tableData = obj.data;
				this.dialogProduct_state = false;
			},
			//删除
			handleDeleteClick(index){
				console.log(index)
			},
			//跳转新建商品
			handleAddGoodsClick(){
				this.$router.push({path:'goodsDetail',query:{editMsg:null}})
			},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
	
		.view {
			background: #fff;
			height: 100%;
	
			border-radius: 20px;
			display: flex;
			flex-direction: column;
	
			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}
			.search {
				display: flex;
				align-items: center;
				height: 80px;
				font-size: 14px;
				padding: 0 20px;
			
				.serchName {
					margin-right: 12px;
				}
			
				.btnView {
					flex: 1;
					text-align: right;
				}
			}
			
			.viewC {
				flex: 1;
				overflow-y: scroll;
			}
	
			.viewB {
				height: 68px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				box-sizing: border-box;
				border-top: solid 1px #F2F2F2;
			}
	
			.viewC::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
