<template>
	<div class="HSMask">
		<div class="HSDialog" style="min-height: 360px;width: 432px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">{{dialogTitle}}</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogContent">
				<el-input type="textarea"  placeholder="请输入内容" v-model="remarkStr" resize="none" rows="8" maxlength="100">
				</el-input>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			remarkType: {
				default: 0 // 0入库备注 1出库备注 2盘点备注 
			},
			remark:{
				default:''
			}
		},
		data() {
			return {
				dialogTitle: '',
				remarkStr:''
			}
		},
		mounted() {
			if (this.remarkType == 0) {
				this.dialogTitle = '入库备注'
			} else if (this.remarkType == 1) {
				this.dialogTitle = '出库备注'
			} else if(this.remarkType == 2){
				this.dialogTitle = '盘点备注'
			}
			this.remarkStr = this.remark;
		},
		methods: {
			// 关闭
			close() {
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit() {
				this.$emit("submit",{type:"success",data:this.remarkStr})
			}

		}
	};
</script>
<style lang="scss" scoped>
	.HSDialogContent{
		padding: 24px 32px;
	}
</style>
